@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 10px;
  /* 스크롤바의 너비 */
}

::-webkit-scrollbar-track {
  background-color: #535252;
  border-radius: 10px;
  /* 트랙의 배경 색상 */
}

::-webkit-scrollbar-thumb {
  background: #252525;
  border-radius: 10px;
  /* 핸들의 배경 색상 */
}

::-webkit-scrollbar-thumb:hover {
  background: #252525; /* 마우스 오버 시 핸들의 배경 색상 */
}

/* 다크모드 스크롤바 스타일 */
/* .dark::-webkit-scrollbar-thumb */

body {
  margin: 0;
  font-family: "Pretendard-Regular";

  -ms-overflow-style: none;
  scrollbar-width: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

.text-stroke {
  text-shadow: -1px -1px 0 #f5aa15, 1px -1px 0 #f5aa15, -1px 1px 0 #f5aa15,
    1px 1px 0 #f5aa15;
}

.dots_custom {
  display: inline-block;
  vertical-align: middle;
  margin: auto 0;
  padding: 0;
}

.dots_custom li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  padding: 0;
}

.dots_custom li button {
  border: none;
  background: #d1d1d1;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 2px;
  width: 10rem;
  border-radius: 100%;
  padding: 0;
}

@media (max-width: 1413px) {
  .dots_custom li button {
    border: none;
    background: #d1d1d1;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 2px;
    width: 8rem;
    border-radius: 100%;
    padding: 0;
  }
}

@media (max-width: 1279px) {
  .dots_custom li button {
    border: none;
    background: #d1d1d1;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 2px;
    width: 7rem;
    border-radius: 100%;
    padding: 0;
  }
}

@media (max-width: 1023px) {
  .dots_custom li button {
    border: none;
    background: #d1d1d1;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 2px;
    width: 5rem;
    border-radius: 100%;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .dots_custom li button {
    border: none;
    background: #d1d1d1;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 2px;
    width: 4rem;
    border-radius: 100%;
    padding: 0;
  }
}

.dots_custom li.slick-active button {
  background-color: #282828;
}

@keyframes scale-up-down {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.FaMeetup {
  animation: scale-up-down 2s infinite;
}

.modalContentScroll {
  width: 100%;
  height: 76%;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 1rem;
  overflow-y: scroll;
}

.modalContentScroll::-webkit-scrollbar {
  width: 5px; /* 스크롤바의 너비 */
}

.modalContentScroll::-webkit-scrollbar-track {
  background: #f1f1f1; /* 스크롤바의 배경색 */
  border-radius: 100px;
}

.modalContentScroll::-webkit-scrollbar-thumb {
  background: #ffc35c; /* 스크롤바 핸들(가로 또는 세로로 움직이는 부분)의 색 */
  border-radius: 100px;
}

.modalContentScroll::-webkit-scrollbar-thumb:hover {
  background: #ffc35c; /* 스크롤바 핸들을 마우스로 가리킬 때의 색 */
}

.modalContentScroll2 {
  width: 100%;
  height: 56vh;
  padding: 0 10px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.modalContentScroll2::-webkit-scrollbar {
  width: 8px; /* 스크롤바의 너비 */
}

.modalContentScroll2::-webkit-scrollbar-track {
  background: #f1f1f1; /* 스크롤바의 배경색 */
  border-radius: 100px;
}

.modalContentScroll2::-webkit-scrollbar-thumb {
  background: #ffc35c; /* 스크롤바 핸들(가로 또는 세로로 움직이는 부분)의 색 */
  border-radius: 100px;
}

.modalContentScroll2::-webkit-scrollbar-thumb:hover {
  background: #ffc35c; /* 스크롤바 핸들을 마우스로 가리킬 때의 색 */
}
